<script setup lang="ts">
    import { usePriceFormatter } from '@/composables/formatPrice';
    import type { Cart } from '@containex/portal-backend-api-client';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import Divider from 'primevue/divider';
    import { isMobile } from '@/util/breakpoints';
    import { computed, ref } from 'vue';
    import { useAuthenticationQuery } from '@/composables/authentication';
    import LoginDialog from '@/components/login/LoginDialog.vue';
    import CartRentalInformation from '@/components/cart/CartRentalInformation.vue';
    import CartCranageInformation from '@/components/cart/CartCranageInformation.vue';
    import { useI18n } from 'vue-i18n';
    import { CONTAINER_ARTICLE_TYPES } from '@containex/portal-business-logic';
    import { useCartAction } from '@/composables/cart';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';
    import InconsistentCartStateDialog from '@/components/cart/InconsistentCartStateDialog.vue';
    import { buttonFontWeight500 } from '@/styling/components/button';

    const props = defineProps<{
        cart: Cart;
        showCheckoutButton: boolean;
        isRental: boolean;
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();

    const { isUserLoggedIn } = useAuthenticationQuery();
    const cartAction = useCartAction();
    const checkoutCartAction = useCheckoutCartAction();

    const isLoginDialogVisible = ref(false);
    const isInconsistentCartStateDialogVisible = ref(false);

    const cartTotal = computed(() => (props.showCheckoutButton ? props.cart.subtotal : props.cart.total));

    const cartHasContainerDiscounts = computed(
        () => props.cart.discount?.totalContainers != null && props.cart.discount.totalContainers > 0
    );

    const cartHasAdditionalProductsDiscount = computed(
        () => props.cart.discount?.totalAdditionalProducts != null && props.cart.discount.totalAdditionalProducts > 0
    );

    const filteredContainers = computed(() => {
        return props.cart.items.filter(
            (item) =>
                item.variant?.product != null &&
                CONTAINER_ARTICLE_TYPES.includes(item.variant?.product.article_type ?? '')
        );
    });

    const numberOfFilteredContainers = computed(() => {
        return filteredContainers.value.reduce((sum, current) => sum + current.quantity, 0);
    });

    const containerTotal = computed(() => {
        const allContainers = filteredContainers.value.reduce((sum, current) => sum + (current.total ?? 0), 0);
        if (cartHasContainerDiscounts.value) {
            return allContainers + (props.cart.discount?.totalContainers ?? 0);
        }

        return allContainers;
    });

    const filteredAdditionalProducts = computed(() => {
        return props.cart.items.filter(
            (item) =>
                item.variant?.product != null &&
                item.parent_line_item_id == null &&
                !CONTAINER_ARTICLE_TYPES.includes(item.variant?.product.article_type ?? '')
        );
    });

    const numberOfFilteredAdditionalProducts = computed(() => {
        return filteredAdditionalProducts.value.reduce((sum, current) => sum + current.quantity, 0);
    });

    const additionalProductsTotal = computed(() => {
        const allAdditionalProducts = filteredAdditionalProducts.value.reduce(
            (sum, current) => sum + (current.total ?? 0),
            0
        );
        if (cartHasContainerDiscounts.value) {
            return allAdditionalProducts + (props.cart.discount?.totalAdditionalProducts ?? 0);
        }

        return allAdditionalProducts;
    });

    async function navigateToForgotPassword(): Promise<void> {
        await router.push({ name: Navigation.PasswordForgot });
    }

    async function navigateToSignupSelection(): Promise<void> {
        await router.push({ name: Navigation.Signup });
    }

    async function navigateToCheckout(): Promise<void> {
        const checkoutPath = props.isRental ? Navigation.RentalCheckout : Navigation.SalesCheckout;
        await router.push({ name: checkoutPath });
    }

    async function validateCartAndNavigateToCheckout(): Promise<void> {
        const isValid = await checkoutCartAction.isCheckoutCartConsistentWithDatabaseCart();
        if (isValid) {
            await cartAction.ensureCartExists();
            await navigateToCheckout();
        } else {
            isInconsistentCartStateDialogVisible.value = true;
        }
    }

    async function handleCheckoutButtonClick(): Promise<void> {
        if (isUserLoggedIn.value) {
            await validateCartAndNavigateToCheckout();
        } else {
            isLoginDialogVisible.value = true;
        }
    }

    function handleInconsistentCartStateDialogConfirm(): void {
        isInconsistentCartStateDialogVisible.value = false;
        router.go(0);
    }
</script>

<template>
    <div class="cart-total-price-container">
        <h3 class="text-xl-bold-line-height-auto">{{ t('CART.TOTAL.HEADER') }}</h3>
        <CartRentalInformation v-if="isRental" />
        <div class="cart-sub-price-container">
            <div class="cart-price-label text-base-lg-line-height-auto">
                {{ t('CART.TOTAL.CONTAINER') }}
                <div class="text-md-regular-line-height-auto color-secondary">
                    {{ t('CART.CART_ITEM.QUANTITY') }}: {{ numberOfFilteredContainers }}
                </div>
            </div>
            <div>{{ formatPrice(containerTotal ?? 0) }}</div>
        </div>
        <div v-if="cartHasContainerDiscounts" class="cart-sub-price-container color-discount">
            <div class="cart-price-label text-base-lg-line-height-auto">
                {{ t('CART.TOTAL.DISCOUNT') }}
            </div>
            <div>- {{ formatPrice(cart.discount?.totalContainers ?? 0) }}</div>
        </div>
        <div class="hr-div-spacer">
            <Divider />
        </div>
        <template v-if="numberOfFilteredAdditionalProducts > 0">
            <div class="cart-sub-price-container">
                <div class="cart-price-label text-base-lg-line-height-auto">
                    {{ t('CART.ADDITIONAL_PRODUCTS.TITLE') }}
                    <div class="text-md-regular-line-height-auto color-secondary">
                        {{ t('CART.CART_ITEM.QUANTITY') }}: {{ numberOfFilteredAdditionalProducts }}
                    </div>
                </div>
                <div>{{ formatPrice(additionalProductsTotal ?? 0) }}</div>
            </div>
            <div v-if="cartHasAdditionalProductsDiscount" class="cart-sub-price-container color-discount">
                <div class="cart-price-label text-base-lg-line-height-auto">
                    {{ t('CART.TOTAL.DISCOUNT') }}
                </div>
                <div>- {{ formatPrice(cart.discount?.totalAdditionalProducts ?? 0) }}</div>
            </div>
            <div class="hr-div-spacer">
                <Divider />
            </div>
        </template>
        <CartCranageInformation v-if="isRental" :cart="cart" :small="false" />
        <div class="cart-sub-price-container">
            <div>
                <div class="text-base-bold-line-height-auto">{{ t('CART.TOTAL.TOTAL') }}</div>
                <div class="text-md-regular-line-height-auto cart-total-price-subtext">
                    {{ t('CART.TOTAL.EXCLUDING_VAT') }}
                </div>
            </div>
            <div class="text-base-bold-line-height-auto" data-testid="cart-total-price">
                {{ formatPrice(cartTotal ?? 0) }}
            </div>
        </div>

        <div v-if="cart.items.length > 0 && showCheckoutButton" class="cart-checkout">
            <Button
                class="text-base-semibold-line-height-auto full-width"
                :label="t('CART.CHECKOUT')"
                icon-pos="right"
                data-testid="cart-total-start-checkout-flow-button"
                :disabled="isRental && (cart.rentalStart == null || cart.rentalEnd == null)"
                :dt="buttonFontWeight500"
                @click="handleCheckoutButtonClick"
            />
        </div>
    </div>
    <div v-if="isMobile" class="cart-price-footer-mobile">
        <div class="cart-sub-price-container">
            <div>
                <div class="text-base-semibold-line-height-auto">{{ t('CART.TOTAL.TOTAL_FOOTER') }}</div>
            </div>
            <div class="text-base-semibold-line-height-auto" data-testid="cart-total-price">
                {{ formatPrice(cart.total ?? 0) }}
            </div>
        </div>

        <div v-if="cart.items.length > 0 && showCheckoutButton" class="cart-checkout-footer">
            <Button
                class="text-base-semibold-line-height-auto full-width"
                :label="t('CART.CHECKOUT')"
                icon-pos="right"
                :dt="buttonFontWeight500"
                @click="handleCheckoutButtonClick"
            />
        </div>
    </div>

    <LoginDialog
        v-if="isLoginDialogVisible"
        @cancel-login="isLoginDialogVisible = false"
        @forgot-password="navigateToForgotPassword"
        @login-success="navigateToCheckout"
        @signup="navigateToSignupSelection"
    />

    <InconsistentCartStateDialog
        v-if="isInconsistentCartStateDialogVisible"
        @close="isInconsistentCartStateDialogVisible = false"
        @confirm="handleInconsistentCartStateDialogConfirm"
    />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-total-price-container {
        background: main.$color-background-bluegray;
        padding: main.$spacing-5;
        flex-grow: 1;
        font-size: main.$font-size-3;
    }

    .cart-sub-price-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: main.$spacing-3 0;
        font-size: main.$font-size-3;
    }

    .cart-price-footer-mobile .cart-sub-price-container {
        padding: main.$spacing-5 0;
        font-size: main.$font-size-3;
    }

    .cart-total-price-header {
        padding-bottom: main.$spacing-3;
    }

    .cart-total-price-container :deep(.p-divider-horizontal) {
        padding: main.$spacing-2 0;
        margin: 0;
    }

    .hr-div-spacer {
        padding-top: main.$spacing-2;
    }

    .cart-price-label {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cart-total-price-subtext {
        color: main.$color-secondary;
    }

    .cart-checkout {
        padding-top: main.$spacing-3;
    }

    .cart-price-footer-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: main.$color-white;
        width: 100%;
        padding: 0 main.$spacing-5;
    }

    .cart-checkout-footer {
        padding: main.$spacing-3 0;
    }
</style>
